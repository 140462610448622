define('ember-hash-helper-polyfill/helpers/hash', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hash = hash;
  function hash(_, obj) {
    return obj;
  }

  exports.default = Ember.Helper.helper(hash);
});